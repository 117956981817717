.nav-bar {
    background-color: #147BE7;
    width: full;
    height: 60px;
}

.nav-menu-container {
    position: fixed;
    left: 0;
    top: 0;
}

.nav-menu {
    max-width: 400px;
    min-width: 200px;
    height: 100vh;
    background-color: #2A3646;
}

.nav-menu-header {
    height: 60px;
}

.nav-menu-close-btn:hover {
    background-color: #20272E;
}

.nav-menu-contents {
    position: relative;
    width: 100%;
    background-color: #334255;
}