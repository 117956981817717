.blogs-widget-container * {
    transition: 0.3s;
}

.blogs-widget-container h1 {
    font-family: "alata", sans-serif;
}

.blogs-widget-cards-container {
    max-width: 1100px;
    width: 85%;
    min-height: 350px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    transition: 0.3s;
}

.blogs-widget-card {
    width: 330px;
    min-height: 280px;
    padding: 15px;
    padding-top: 20px;
}

.blogs-widget-card:hover {
    background-color: #0E1F30;
    padding-top: 15px;
    padding-bottom: 20px;
}

.blogs-widget-card-rectimage {
    box-shadow: 6px 15px 20px #000000aa;
}

.blogs-widget-card-content label {
    font-size: 18px;
    text-align: center;
    margin-bottom: 15px;
    cursor: pointer;
}

.blogs-widget-card-content span {
    text-align: center;
    font-size: 12px;
    color: #dddddd;
}

@media(max-width: 1200px) {
    .blogs-widget-cards-container {
        justify-content: center;
        margin-bottom: 80px;
    }
}

@media(max-width:790px) {
    .blogs-widget-container h1 {
        margin-bottom: 30px;
    }

    .blogs-widget-cards-container {
        margin-bottom: 30px;
    }
}