.sec-intro {
    transition: 0.2s;
    height: calc(700px + 68px - 20px);
    background-color: #0E1F30;
}

.sec-intro-container {
    width: 1000px;
    transition: 0.2s;
    height: 700px;
    top: 100px;
}

.sec-intro-container h4 {
    font-size: 70px;
    font-family: 'Caveat', cursive;
    font-weight: 700;
    color: #ffffff;
    text-shadow: 10px 10px 12px #000000A5;
    position: absolute;
    top: 50px;
    left: 50px;
    transform: rotate(-10deg);
}

.sec-intro-container h5 {
    font-size: 90px;
    font-family: 'Satisfy', cursive;
    font-weight: 400;
    color: #DBDBDB;
    position: absolute;
    top: 70px;
    left: 50%;
    transform: rotate(5deg);
}

.sec-intro-container h1 {
    font-size: 140px;
    font-family: 'Bowlby One SC', cursive;
    letter-spacing: 5px;
    font-weight: 400;
    color: #EC604A;
    position: absolute;
    top: 135px;
    left: 48%;
    transform: translate(-50%);
}

.sec-intro-container h3 {
    font-size: 120px;
    font-family: 'Caveat brush', cursive;
    font-weight: bolder;
    letter-spacing: 10px;
    font-style: italic;
    color: #3963B7;
    position: absolute;
    top: 280px;
    left: 48%;
    transform: translate(-50%);
}

.sec-intro-container h2 {
    font-size: 110px;
    font-family: 'Bowlby One SC', cursive;
    letter-spacing: 5px;
    font-weight: 400;
    color: #EC604A;
    position: absolute;
    top: 420px;
    left: 44%;
    transform: translate(-50%);
}

.intro-widget-footer-div {
    top: -20px;
    width: 100%;
    background-image: url('../elements/intro-widget-footer.svg');
    background-position: center;
    background-size: cover;
    height: 68px;
}

/* Media Query */

@media(max-width: 1050px) {
    .sec-intro-container {
        width: 900px;
    }
}

@media(max-width: 950px) {
    .sec-intro {
        height: calc(550px + 68px - 20px);
    }

    .sec-intro-container {
        width: 700px;
        height: 550px;
        top: 0;
    }

    .sec-intro-container h4 {
        font-size: 60px;
    }

    .sec-intro-container h5 {
        font-size: 100px;
    }

    .sec-intro-container h1 {
        font-size: 110px;
        top: 150px;
        left: 40%;
    }

    .sec-intro-container h3 {
        font-size: 80px;
        top: 285px;
        left: 43%;
    }

    .sec-intro-container h2 {
        font-size: 100px;
        top: 370px;
        left: 38%;
    }

    .sec-intro-container img {
        height: 500px;
        top: 50px;
    }



}

@media(max-width: 800px) {
    .sec-intro {
        height: calc(450px + 68px - 20px);
    }

    .sec-intro-container {
        width: 500px;
        height: 450px;
    }

    .sec-intro-container h4 {
        font-size: 40px;
        top: 90px;
        left: 0px;
    }

    .sec-intro-container h5 {
        font-size: 60px;
        left: 45%;
        top: 90px;
    }

    .sec-intro-container h1 {
        font-size: 90px;
        top: 150px;
        left: 35%;
    }

    .sec-intro-container h3 {
        font-size: 75px;
        top: 240px;
        left: 40%;
    }

    .sec-intro-container h2 {
        font-size: 85px;
        top: 320px;
        left: 34%;
    }

    .sec-intro-container img {
        height: 400px;
        top: 70px;
        right: -10px;
    }


}

@media(max-width: 550px) {
    .sec-intro-container {
        width: 400px;
    }

    .sec-intro-container h4 {
        font-size: 35px;
        top: 80px;
        left: 20px;
    }

    .sec-intro-container h5 {
        font-size: 50px;
        left: 45%;
        top: 110px;
    }

    .sec-intro-container h1 {
        font-size: 70px;
        top: 160px;
        left: 40%;
    }

    .sec-intro-container h3 {
        font-size: 45px;
        top: 240px;
        left: 30%;
    }

    .sec-intro-container h2 {
        font-size: 60px;
        top: 290px;
        left: 30%;
    }
}

@media(max-width: 450px) {
    .sec-intro-container {
        width: 310px;
    }

    .sec-intro-container h4 {
        font-size: 30px;
        top: 40px;
        left: 0px;
    }

    .sec-intro-container h5 {
        font-size: 40px;
        left: 30%;
        top: 70px;
    }

    .sec-intro-container h1 {
        font-size: 50px;
        top: 120px;
        left: 30%;
    }

    .sec-intro-container h3 {
        font-size: 30px;
        top: 190px;
        left: 22%;
    }

    .sec-intro-container h2 {
        font-size: 40px;
        top: 230px;
        left: 20%;
    }
}