.achievements-widget-container h1 {
    font-family: "alata", sans-serif;
}

.cards-group {
    overflow-x: hidden;
    overflow-y: hidden;
    height: 330px;
    width: 888px;
}

.cards-container {
    transform: translate(0);
    transition: 0.3s;
    height: 270px;
}

.cards-container * {
    transition: 0.4s;
}